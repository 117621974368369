export default function getActualBarsFromLteSignal(bars: number | undefined) {
  // Our model has bars from 0 to 5 but the icon has from 0 to 4
  const adjustedBars = bars ?? 0;

  if (adjustedBars < 0) {
    return 0;
  }

  if (adjustedBars > 4) {
    return 4;
  }

  return adjustedBars;
}
